import { Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './utilities/auth.guard';
import { FileuploadComponent } from './fileupload/fileupload.component';
import { LogoutComponent } from './logout/logout.component';
import { UserregistrationComponent } from './userregistration/userregistration.component';

export const appRoutes: Routes = [
    { path: 'userregistration', component: UserregistrationComponent },
    { path: 'login', component: LoginComponent },
    { path: 'home', loadChildren: () => import('src/app/home/home.module').then(m => m.HomeModule), canActivate: [AuthGuard] },
    { path: 'logout', component: LogoutComponent },
    { path: '', redirectTo: 'login', pathMatch: 'full' }

];


