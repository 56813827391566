import { Injectable } from '@angular/core';
import { IAuthenticationService } from './iauthentication.service';
import { LoginModel } from './loginmodel';
import { HttpClient } from '@angular/common/http';
import { APP_BASE_URL } from '../common/common.constant';
import { UserModel } from '../userregistration/user.model';

@Injectable()
export class AuthenticationService implements IAuthenticationService {
    constructor(private http: HttpClient) { }
    postAuthenticate(LoginModel: LoginModel) {
        return this.http.post(APP_BASE_URL + 'login/authenticate', LoginModel);
    }

    postCreateUser(userModel: any) {
        return this.http.post(APP_BASE_URL + 'login/register', userModel);
    }

    deleteUser() {
        return this.http.delete(APP_BASE_URL + 'login/delete');
    }

}
