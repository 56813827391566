import { Component, OnInit } from '@angular/core';
import { LoginModel } from './loginmodel';
import { IAuthenticationService } from './iauthentication.service';
import { AuthenticationService } from './authentication.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [{ provide: IAuthenticationService, useClass: AuthenticationService }, MessageService]
})
export class LoginComponent implements OnInit {
    loginModel: LoginModel;
    errMessage;
    display;
    languages = [
        { label: 'English', value: 'EN' }
    ];
    spinner = false;
    selectedLanguage = 'EN';
    serviceStatus = false;
    constructor(private authService: IAuthenticationService, private fb: UntypedFormBuilder, private router: Router, private messageService: MessageService) {
        this.loginModel = new LoginModel();
    }
    submitted = false;
    loginForm: UntypedFormGroup;

    ngOnInit() {
        this.initLoginForm();
    }

    get f() {
        return this.loginForm.controls;
    }

    initLoginForm() {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.minLength(2), Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            rememberMe: true
        });
    }

    forgotPassword() {

    }

    userregistration() {
        this.router.navigate(['/userregistration']);
    }



    login() {
        if (this.loginForm.status == 'VALID') {
            this.spinner = true;
            this.submitted = false;
            this.loginModel.email = this.loginForm.value.email;
            this.loginModel.password = this.loginForm.value.password;
            this.loginModel.rememberMe = this.loginForm.value.rememberMe;
            if (this.loginModel.email && this.loginModel.password) {
                this.authService.postAuthenticate(this.loginModel).subscribe((res) => {
                    this.serviceStatus = false;
                    if (res.success == true) {
                        if (this.loginModel.rememberMe) {
                            localStorage.setItem('isAuthenticated', 'true');
                            localStorage.setItem('firstName', res.data.firstName);
                            localStorage.setItem('lastName', res.data.lastName);
                            localStorage.setItem('token', res.data.token);
                            localStorage.setItem('role', res.data.role);
                            localStorage.setItem('email', this.loginForm.value.email);
                        } else {
                            sessionStorage.setItem('isAuthenticated', 'true');
                            sessionStorage.setItem('firstName', res.data.firstName);
                            sessionStorage.setItem('lastName', res.data.lastName);
                            sessionStorage.setItem('token', res.data.token);
                            sessionStorage.setItem('role', res.data.role);
                            sessionStorage.setItem('email', this.loginForm.value.email);
                        }
                        this.spinner = false;
                        this.router.navigate(['home']);
                    } else {
                        this.spinner = false;
                        this.errMessage = res.error;
                        this.loginForm.setErrors(res.error);
                    }
                },
                (error) => {
                    this.errMessage = null;
                    this.spinner = false;
                    this.serviceStatus = true;
                    this.messageService.add({ severity: 'error', summary: 'Service unavailable.' });
                });
            }
        } else {
            this.submitted = true;
        }
    }

}
