<div class="container-fluid">
  <div class="content-box" style="margin-bottom:1%;">
    <div class="container-login">
      <div class="login">
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-12">
            <div class="logo"><img src="/assets/images/sophos-logo-white.png" /></div>
            <form [formGroup]="registerForm" (ngSubmit)="register(registerForm)">
              <h3>
                Create Account
              </h3>
              <label>First Name</label><br />
              <input type="text" formControlName="firstName" required /><br />
              <br />
              <label>Last Name</label><br />
              <input type="text" formControlName="lastName" required /><br />
              <br />
              <label>Email</label><br />
              <input type="email" formControlName="email" required /><br />
              <br />
              <label>Password</label><br />
              <input type="password" formControlName="password" required />
              <br /><br />
              <label>Confirm Password</label><br />
              <input type="password" formControlName="confirmPassword" required />
              <br />
              <br /><br />
              <button class="green-button">Create</button><br />
              <div *ngIf="isFieldValid('firstName')" class="invalid-feedback">
                <div class="error">Please Enter First Name</div>
              </div>
              <div *ngIf="isFieldValid('lastName')" class="invalid-feedback">
                <div class="error">Please Enter Last Name</div>
              </div>
              <div *ngIf="isFieldValid('email')" class="invalid-feedback">
                <div class="error">Please Enter Email</div>
              </div>
              <div *ngIf="isFieldValid('password')" class="invalid-feedback">
                <div class="error">Please Enter Password</div>
              </div>
              <div *ngIf="isFieldValid('confirmPassword')" class="invalid-feedback">
                <div class="error">Please Enter Confirm Password</div>
              </div>
              <div *ngIf="ispasswordValid('password','confirmPassword') " class="invalid-feedback">

                <div class="error">Password and Confirm password not matched</div>

              </div>
            </form>
          </div>
        </div>

        <div class="account-box">
          <div class="p-grid ">
            <div *ngIf="!(toLogin || serviceStatus || responseError)" class="p-col-12 p-md-12 p-lg-12">
              <div></div>
              <p>
                Go to <span style="cursor:pointer; text-align:center;" [routerLink]="['/login']"> login </span>
              </p>
            </div>
            <div *ngIf="toLogin" class="p-col-12 p-md-12 p-lg-12">
              <div></div>
              <p>
                Your account is created, click <span style="cursor:pointer; text-align:center;" [routerLink]="['/login']"> Sign In </span>to login.
              </p>
            </div>
            <div *ngIf="serviceStatus" class="p-col-12 p-md-12 p-lg-12">
              <div></div>
              <p>
                Service unavailable to accept request, please try after sometime. click <span style="cursor:pointer; text-align:center;" [routerLink]="['/login']"> Sign In </span>to login.
              </p>
            </div>
            <div *ngIf="responseError" class="p-col-12 p-md-12 p-lg-12">
              <div></div>
              <p>
                Unable to process the request, please try after sometime. click <span style="cursor:pointer; text-align:center;" [routerLink]="['/login']"> Sign In </span>to login.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>
