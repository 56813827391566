<div class="container-fluid">
  <div class="content-box" style="margin-bottom:1%;">

    <div class="container-login">
      <div class="login">
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-12">
            <div class="logo"><img src="/assets/images/sophos-logo-white.png" /></div>
            <div class="logo2">
              <span class="icon-sophos-central logo-icon"></span> <span class="logo-text">Migration Assistant - Sophos Firewall</span>
              <p>Move your configuration backups from SonicWall or Fortinet to Sophos Firewall using the migartion assistant</p>
            </div>
            <!--<div class="tab-box"> <span> Sonicwall </span> <i class="fa fa-angle-right"></i>  <span> XG Firewall</span> </div>-->
            <form [formGroup]="loginForm" (ngSubmit)="login()">
              <h3>
                Sign in
              </h3>
              <label>Email</label><br />
              <input type="email" formControlName="email" required /><br />
              <br />
              <label>Password</label><br />
              <input type="password" formControlName="password" required /><br />
              <label class="checkbox">
                Remember me
                <input type="checkbox" formControlName="rememberMe" checked="checked">
                <span class="checkmark"></span>
              </label>
              <br /><br /> <br /><br />
              <button class="green-button">Sign In</button><br />

              <p-progressSpinner *ngIf="spinner" [style]="{width: '50px', height: '50px'}" strokeWidth="8"></p-progressSpinner>

              <div *ngIf="f.email.invalid && submitted " class="invalid-feedback">
                <div class="error" *ngIf="f.email.errors.required ">Email is required</div>
                <div class="error" *ngIf="f.email.errors.email ">Email must be a valid email address</div>
              </div>
              <div *ngIf="f.password.invalid && submitted" class="invalid-feedback">
                <div class="error" *ngIf="f.password.errors.required">Password is required</div>
                <div class="error" *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>
              </div>
              <div *ngIf="errMessage" class="invalid-feedback">
                <div class="error">{{errMessage}}</div>
              </div>
              <div *ngIf="serviceStatus" class="p-col-12 p-md-12 p-lg-12">
               <p  class="error">
                  Service unavailable to accept request
                </p>
              </div>
            </form>
          </div>
        </div>
        <div class="account-box">
          <div class="p-grid ">
            <div class="p-col-6 p-md-6 p-lg-6">
              <div style="cursor:pointer;" (click)="userregistration()">Create Account</div>
            </div>
          </div>
        </div>
        <!--<hr />
        <div class="account-box">
          <div class="p-grid ">
            <div class="p-col-6 p-md-6 p-lg-6">
              <p-dropdown [options]="languages" [(ngModel)]="selectedLanguage"></p-dropdown>
            </div>
            <div class="p-col-6 p-md-6 p-lg-6">
              <div style="cursor:pointer;" class="right">What is Sophos ID</div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
  </div>
</div>
<p-toast></p-toast>

