import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
    constructor(private router: Router) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const isAuth = sessionStorage.getItem('token') || localStorage.getItem('token');
        if (isAuth) {
            req = req.clone({
                setHeaders: {
                    token: isAuth
                }
            });
        }
        return next.handle(req).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log(event);
                    if (event.status === 401 || event.status === 500) {
                        this.router.navigate(['/login']);
                    }
                }
                return event;
            }));
    }

}
