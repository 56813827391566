import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { IAuthenticationService } from '../login/iauthentication.service';
import { AuthenticationService } from '../login/authentication.service';
import { UserModel } from './user.model';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-userregistration',
    templateUrl: './userregistration.component.html',
    styleUrls: ['./userregistration.component.scss'],
    providers: [{ provide: IAuthenticationService, useClass: AuthenticationService }, MessageService]
})
export class UserregistrationComponent implements OnInit {
    toLogin = false;
    serviceStatus = false;
    responseError = false;

    constructor(private router: Router, private fb: UntypedFormBuilder, private authService: IAuthenticationService, private messageService: MessageService) { }

    ngOnInit() {
        this.initRegisterForm();
    }

    registerForm: UntypedFormGroup;


    initRegisterForm() {
        this.registerForm = this.fb.group({
            firstName: ['', [Validators.required, Validators.minLength(2)]],
            lastName: ['', [Validators.required, Validators.minLength(2)]],
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
            confirmPassword: ''
        });
    }

    register(form) {
        if (this.registerForm.valid) {
            if (form.status == 'VALID') {
                const userModel = new UserModel();
                userModel.firstName = form.value.firstName;
                userModel.lastName = form.value.lastName;
                userModel.email = form.value.email;
                userModel.password = form.value.password;
                const params = {
                    data: userModel
                };
                this.authService.postCreateUser(params).subscribe((res) => {
                    console.log('error', res);
                    if (res.success) {
                        this.toLogin = true;
                        this.serviceStatus = false;
                        this.responseError = false;
                        this.messageService.add({ severity: 'success', summary: 'Account is created Successfully.' });
                    } else {
                        this.responseError = true;
                        this.toLogin = false;
                        this.serviceStatus = false;
                        this.messageService.add({ severity: 'error', summary: 'Request not processed.' });
                    }
                },
                (error) => {
                    this.responseError = false;
                    this.toLogin = false;
                    this.serviceStatus = true;
                    this.messageService.add({ severity: 'error', summary: 'Service unavailable.' });
                });
            }
        } else {
            this.validateAllFormFields(this.registerForm);
        }
    }
    isFieldValid(field: string) {
        return !this.registerForm.get(field).valid && this.registerForm.get(field).touched  ;
    }

    ispasswordValid(field1: string, field2: string) {
        if ((this.registerForm.get(field1).valid && this.registerForm.get(field1).touched) && (this.registerForm.get(field2).valid && this.registerForm.get(field2).touched)) {
            if (this.registerForm.get(field1).value != this.registerForm.get(field2).value) {
                this.registerForm.setErrors({ 'invalid': true });
                return true;
            }
        };
    }

    onSubmit() {
        console.log(this.registerForm);
        if (this.registerForm.valid) {
            console.log('form submitted');
        } else {
            this.validateAllFormFields(this.registerForm);
        }
    }

    validateAllFormFields(formGroup: UntypedFormGroup) {
        Object.keys(formGroup.controls).forEach(field => {
            console.log(field);
            const control = formGroup.get(field);
            if (control instanceof UntypedFormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof UntypedFormGroup) {
                this.validateAllFormFields(control);
            }
        });
    }

    login() {
        this.router.navigate(['/login']);
    }

}
