import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {AccordionModule} from 'primeng/accordion';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuard } from './utilities/auth.guard';
import { MegaMenuModule } from 'primeng/megamenu';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FileUploadModule } from 'primeng/fileupload';
import { ChartModule } from 'primeng/chart';
import { LogoutComponent } from './logout/logout.component';
import { DialogModule } from 'primeng/dialog';
import { UpdatePasswordComponent } from './update-password/update-password.component';
import { ContentComponent } from './content/content.component';
import { DropdownModule } from 'primeng/dropdown';
import { UserregistrationComponent } from './userregistration/userregistration.component';
import { RequestInterceptor } from './utilities/interceptor';
import { ToastModule } from 'primeng/toast';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Chart } from 'chart.js';
import { NgChartsModule } from 'ng2-charts';
import { RadioButtonModule } from 'primeng/radiobutton';
import { MessageService } from 'primeng/api';
// import Chart from 'chart.js/dist/Chart.js';

// Custom error handler
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor() {}
    
    handleError(error: any) {
        console.error('An error occurred:', error);
        // Log error details
        console.error('Error details:', {
            message: error.message,
            stack: error.stack,
            timestamp: new Date().toISOString()
        });
    }
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        LogoutComponent,
        UserregistrationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule, HttpClientModule,
        RouterModule.forRoot(appRoutes, {}),
        MegaMenuModule,
        TableModule,
        FormsModule,
        OverlayPanelModule,
        FileUploadModule,
        DialogModule,
        DropdownModule,
        ToastModule,
        ProgressSpinnerModule,
        SidebarModule,
        AccordionModule,
        ConfirmDialogModule,
        ChartModule,
        NgChartsModule,
        RadioButtonModule,
    ],
    providers: [
        AuthGuard,
        MessageService,
        { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
